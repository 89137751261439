import { reloadResidencies, updateCurrentFiltersVar } from './residenciesListMap';

/**
 * Residences filters
 * Here is the js that is managing the Dropdown openings/closing
 * and the first active element swap inside the toggle button
 *
 */

const filters = Array.from(document.getElementsByClassName('js-filter'));

if(filters) {
  for(const filterEl of filters) {
    const self = filterEl;
    const filterToggle = self.querySelector('.filter__toggle');
    const filterUpdateBtn = self.querySelector('.js-update-data');
    const filterResetBtn = self.querySelector('.js-reset-filter');
    const filterDropdown = self.querySelector('.filter__dropdown');

    filtersUpdate(self);

    filterToggle.addEventListener('click', function(ev) {
      if(document.querySelector('.filter--dropdown-active') && !self.classList.contains('filter--dropdown-active')) {
        document.querySelector('.filter--dropdown-active').classList.remove('filter--dropdown-active');
      }
      self.classList.toggle('filter--dropdown-active');
    });

    filterUpdateBtn.addEventListener('click', function (ev) {
      self.classList.remove('filter--dropdown-active');

      filtersUpdate(self);
    });

    filterResetBtn.addEventListener('click', function (ev) {
      filtersUpdate(self);
    });

    document.addEventListener('click', (e) => {
      if (!self.contains(e.target)) {
        self.classList.remove('filter--dropdown-active');
      }
    });
  }
}

function filtersUpdate(parent) {
  const filterLabel = parent.querySelector('.filter__toggle__label');
  const filterActiveValue = parent.querySelector('.filter__toggle__value');
  const filterCounter = parent.querySelector('.filter__toggle__counter');

  const checkboxes = Array.from(parent.getElementsByTagName('input'));
  const checkboxesChecked = [];

  for (const checkbox of checkboxes) {
    if (checkbox.checked) {
      checkboxesChecked.push(checkbox);
    }
  }

  if (checkboxesChecked.length) {
    filterLabel.classList.add('hide');
    filterActiveValue.classList.remove('hide');
    const filterValue = checkboxesChecked[0].value;

    /* eslint-disable */
    switch(true) {
      case parent.classList.contains('js-filter-prix'):
        filterActiveValue.innerHTML = `${filterValue}k€`;
        break;
      case parent.classList.contains('js-filter-chambres'):
        filterActiveValue.innerHTML = checkboxesChecked[0].nextElementSibling.innerHTML;
        break;
      default:
        filterActiveValue.innerHTML = filterValue;
    }
    /* eslint-enable */

    if (checkboxesChecked.length > 1) {
      filterCounter.classList.remove('hide');
      filterCounter.innerHTML = `+${checkboxesChecked.length - 1}`;
    } else {
      filterCounter.classList.add('hide');
    }
  } else {
    filterLabel.classList.remove('hide');
    filterActiveValue.classList.add('hide');
    filterCounter.classList.add('hide');
  }
}

const moreFilters = document.querySelector('.js-more-filters');

if(moreFilters) {
  const updateBtn = moreFilters.querySelector('.button');
  const moreFiltersReset = moreFilters.querySelector('.js-reset-filters');
  moreFiltersUpdate(true);

  updateBtn.addEventListener('click', function (ev) {
    moreFiltersUpdate();
  });

  moreFiltersReset.addEventListener('click', function (ev) {
    const checkboxes = Array.from(moreFilters.getElementsByTagName('input'));
    checkboxes.forEach((input) => {
      input.checked = false;
    });
  });
}

function moreFiltersUpdate(init) {
  const filtersCount = moreFilters.querySelector('.more-filters__count');
  const checkboxes = Array.from(moreFilters.getElementsByTagName('input'));
  const checkboxesChecked = [];

  for (const checkbox of checkboxes) {
    if (checkbox.checked) {
      checkboxesChecked.push(checkbox);
    }
  }

  if (checkboxesChecked.length) {
    filtersCount.classList.remove('hide');
    filtersCount.innerHTML = checkboxesChecked.length;
  } else {
    filtersCount.classList.add('hide');
  }

  if (!init) {
    updateCurrentFiltersVar();
    reloadResidencies();
  }
}

const filtersToggle = document.querySelector('.js-filters-toggle');

// Mobile toggle as filters are hidden by default
if(filtersToggle) {
  let mobileFilters;
  if (filters && moreFilters) {
    mobileFilters = filters;
    mobileFilters.push(moreFilters)
  } else {
    mobileFilters = filters ? filters : moreFilters;
  }

  filtersToggle.addEventListener('click', () => {
    mobileFilters.forEach((filter) => {
      filter.classList.toggle('residences-filters__item--active');
    })
  });
}

import { updateCurrentFiltersVar, reloadResidencies } from './residenciesListMap';

const getDom = (query) => {
  if (query.jquery) {
    return query[0];
  }

  if (query instanceof HTMLElement) {
    return query;
  }

  if (isHtmlString(query)) {
    const tpl = document.createElement('template');
    tpl.innerHTML = query.trim(); // Never return a text node of whitespace as the result

    return tpl.content.firstChild;
  }

  return document.querySelector(query);
};

const isHtmlString = (arg) => {
  return typeof arg === 'string' && arg.indexOf('<') > -1;
};

export default function(userOptions) {
  const self = this;

  const mandatoryResetClass = 'dropdown-footer-reset';
  const mandatoryCloseClass = 'dropdown-footer-close';

  const options = Object.assign({
    footerClass           : 'dropdown-footer',
    footerContainerClass  : 'dropdown-footer-container',
    resetLabel            : 'Effacer',
    resetClass            : '',
    closeLabel            : 'Ok',
    closeClass            : '',

    html: (data) => {
      return (
        '<footer class="' + data.footerClass + '">' +
          '<div class="' + data.footerContainerClass + '">' +
            '<button class="'+ mandatoryResetClass + ' ' + data.resetClass + '">' + data.resetLabel + '</button>' +
            '<button class="'+ mandatoryCloseClass + ' ' + data.closeClass + '">' + data.closeLabel + '</button>' +
          '</div>' +
        '</footer>'
      );
    }
  }, userOptions);

  self.on('initialize', () => {
    const footer = getDom(options.html(options));

    const reset = footer.querySelector(`.${mandatoryResetClass}`);
    reset.addEventListener('click', function (evt) {
      if( self.isDisabled ) {
        return;
      }
      self.clear();
      if( self.settings.mode === 'single' && self.settings.allowEmptyOption ){
        self.addItem('');
      }
    });

    const close = footer.querySelector(`.${mandatoryCloseClass}`);
    if( close ) {
      close.addEventListener('click',(evt) => {
        self.close();
        updateCurrentFiltersVar();
        reloadResidencies();
      });
    }

    self.dropdown.appendChild(footer);
  });
}

import Mustache from 'mustache';
import { lazyLoadObserve } from './lazyLoad';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { offCanvas } from './offcanvas';
import { lodgingsInteractions } from './lodgingsInteractions';
import { mModals } from "./micromodal";
import Jump from 'jump.js'
import { easeInOutCubic } from 'ez.js';

const postsList = document.querySelector('.js-posts-list');
const basename = `${location.origin}/programmes-neufs/`;
const mapElement          = document.querySelector('.js-gmap');
const isLodgingsViewInput = document.querySelector('input[id="is-lodgings-view"]');
const isMapViewInput      = document.querySelector('input[id="is-map-view"]');
const postsCounterElement = document.querySelector('.js-posts-counter');
const paginationElement   = document.querySelector('.js-pagination');
const updateButtons       = document.querySelectorAll('.js-update-data');
const resetFilterButtons  = document.querySelectorAll('.js-reset-filter');
const defaultZoom = 6;
const defaultPositionLat = 46.585398;
const defaultPositionLng = 3.320208;
const filtersSlug = ['lieu', 'prix', 'chambres', 'fiscalite', 'livraison', 'annee_livraison', 'avancement', 'types_logement', 'atouts', 'prestations_residence', 'prestations_logement', 'offres'];
const firstLoad = true;
let residencyInfowindowTemplate;
let displaymode;
let currentFilters = [];
let xhr, loadPostsAjaxUrl;
let nbResidencies;
let nbLodgings;
let map, cluster;
let maxZoom = 15;
let markers = [];
let markerIcon = mapElement ? mapElement.dataset.markerIcon : null;

updateCurrentFiltersVar(firstLoad);

if (mapElement) {
  markerIcon = mapElement.dataset.markerIcon;
  initMap();
  displaymode = document.querySelector('.js-displaymode').dataset.displaymode ?? 'residences';
  residencyInfowindowTemplate = document.querySelector('#residency_infowindow').innerHTML;
}

if ( displaymode === 'carte' ) {
  loadResidenciesToMap(map, true);
}

// window.paged = 1;
// let maxnumpages = (document.querySelector('.js-posts-list-item')) ?
//   document.querySelector('.js-posts-list-item').getAttribute('data-maxnumpages') : 0;

// Events on update / reset buttons
if ( updateButtons.length ){
  updateButtons.forEach((updateButton) => {
    updateButton.addEventListener('click', function(){

      updateCurrentFiltersVar();
      reloadResidencies();

    });
  });
}
if ( resetFilterButtons.length ){
  resetFilterButtons.forEach((resetFilterButton) => {
    resetFilterButton.addEventListener('click', function(){
      document.querySelectorAll('input[name="' + this.dataset.slug + '"]:checked').forEach((input) => {
        input.checked = false;
      });
    });
  });
}

if (postsList) {
  // Events on switchers
  isLodgingsViewInput.addEventListener('change', (event) => {

    postsList.classList.remove('hide');
    if ( mapElement )
      mapElement.classList.add('hide');
    if (isMapViewInput)
      isMapViewInput.checked = false;
    displaymode = event.currentTarget.checked ? 'logements' : 'residences';

    if (displaymode === 'residences') {
      postsList.classList.add('residences__list--minimal');
    } else {
      postsList.classList.remove('residences__list--minimal');
    }
    reloadResidencies();
  });

  if (isMapViewInput) {
    isMapViewInput.addEventListener('change', (event) => {

      if (event.currentTarget.checked) {
        document.querySelector('.js-displaymode').parentNode.classList.add('residences--map');
        postsList.classList.add('hide');
        mapElement.classList.remove('hide');
        postsCounterElement.parentNode.classList.add('hide');
        document.querySelector('.js-view-switcher').classList.add('hide');
        if (paginationElement) {
          paginationElement.classList.add('hide');
        }

        let scrollTarget, scrollOffset;

        if (document.getElementById('filters')) {
          scrollTarget = document.getElementById('filters');
          scrollOffset = 0;
        } else {
          scrollTarget = document.querySelector('.main');
          scrollOffset = document.querySelector('.js-fixed-nav').offsetHeight * -1;
        }

        setTimeout(() => {
          Jump(scrollTarget, {
            offset: scrollOffset,
            duration: 800,
            easing: easeInOutCubic
          })
        }, 250);

      } else {
        document.querySelector('.js-displaymode').parentNode.classList.remove('residences--map');
        postsList.classList.add('residences__list--minimal');
        postsList.classList.remove('hide');
        mapElement.classList.add('hide');
        if (paginationElement) {
          paginationElement.classList.remove('hide');
        }
        document.querySelector('.js-view-switcher').classList.remove('hide');
      }
      isLodgingsViewInput.checked = false;
      displaymode = event.currentTarget.checked ? 'carte' : 'residences';
      updateCurrentFiltersVar();
      reloadResidencies();
    });
  }
}

/**
 * Update currentFilters variable, according to all front choices
 */
export function updateCurrentFiltersVar(firstLoad) {
  // Empty current filters
  currentFilters = [];

  // Add filters if selected in related inputs
  if ( document.querySelector('.js-location-selector') ) {

    const selectedOptions = document.querySelector('.js-location-selector').selectedOptions;
    let selectedLocations = [];
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    for (let i = 0; i < selectedOptions.length; i++) {
      selectedLocations.push(selectedOptions[i].value);
    }

    if (selectedOptions.length === 0 && firstLoad) {
      selectedLocations = urlParams.get('lieu') ? urlParams.get('lieu').split("_") : [];
    }

    currentFilters['lieu'] = selectedLocations;

  }

  // Start at index 1 to avoid resetting locations (['lieu'])
  for (let i = 1; i < filtersSlug.length; i++) {
    let filterValues = [];
    let currentFilterCheckedInputs = document.querySelectorAll('input[name="' + filtersSlug[i] + '"]:checked');

    for (let j = 0; j < currentFilterCheckedInputs.length; j++) {
      filterValues.push(currentFilterCheckedInputs[j].value);
    }
    currentFilters[filtersSlug[i]] = filterValues;
  }

  if ( ! postsList ) return;

  // Add filters if fixed by thematic list pages
  for (let k = 0; k < filtersSlug.length; k++) {
    if ( typeof(postsList.dataset[filtersSlug[k]]) == 'undefined' ){
      continue;
    }
    currentFilters[filtersSlug[k]] = postsList.dataset[filtersSlug[k]].split(',');
  }
}

/**
 * Dispatch events on reloading Residencies (List or Map)
 */
export function reloadResidencies(){

  // Disabled during ajax load
  if ( ! postsList || postsList.classList.contains('loading') )
    return;

  rewriteURL();
  postsList.innerHTML = '';
  if ( paginationElement )
    paginationElement.innerHTML = '';

  if ( displaymode == 'carte' ) {
    loadResidenciesToMap(map);
  } else {
    loadAddResidenciesIntoList();
  }

}

/**
 * Load and add residencies into List
 */
function loadAddResidenciesIntoList(){

  if ( ! postsList ) return;
  // Disable filterSubmit during ajax load
  const filtersContainer = document.getElementById('filters');

  if(filtersContainer) {
    filtersContainer.classList.add('residences-filters--loading');
  }

  const modeToggles = Array.from(document.getElementsByClassName('js-mode-toggle'));
  modeToggles.forEach((modeToggle) => {
    modeToggle.classList.add('loading');
  })

  // Ajax Request
  xhr = new XMLHttpRequest();
  loadPostsAjaxUrl = `${ajax_js.ajaxurl}?action=display_requested_residencies&m=${displaymode}&paged=1`;

  for (let currentFilter in currentFilters) {
    if (currentFilters[currentFilter].length) {
      loadPostsAjaxUrl += '&' + currentFilter + '=' + currentFilters[currentFilter].join('_');
    }
  }
  xhr.open('GET', loadPostsAjaxUrl);
  xhr.onload = function() {
    if (xhr.status === 200) {

      // Add page results to list
      postsList.innerHTML += xhr.responseText;

      resetPagination();

      // Lazy Load Observer
      const observer = lazyLoadObserve();
      observer.observe();

      // On 1st page, set maxnumpage
      // if(window.paged == 1)
      //   maxnumpages = (document.querySelector('.js-posts-list-item')) ?
      //     document.querySelector('.js-posts-list-item').getAttribute('data-maxnumpages') : 0;

      updatePostsCounter();

      // Remove loading class
      if (filtersContainer) {
        filtersContainer.classList.remove('residences-filters--loading');
      }

      modeToggles.forEach((modeToggle) => {
        modeToggle.classList.remove('loading');
      })

      offCanvas();
      lodgingsInteractions();
      mModals();
    }
  };
  xhr.send();
}

/**
 * Update Residencies & Lodgings counter
 */
function updatePostsCounter() {

  if ( ! postsList ) return;

  const posts = postsList.querySelectorAll('.js-post-item');

  nbResidencies = posts.length && posts[0].dataset.nbresidencies ? posts[0].dataset.nbresidencies : '0';
  nbLodgings    = posts.length && posts[0].dataset.nblodgings    ? posts[0].dataset.nblodgings    : '0';

  postsCounterElement.parentNode.classList.remove('hide');
  postsCounterElement.innerHTML =
    ( displaymode == 'logements' ? '<strong>' : '' ) +
    nbLodgings + ' logement' + (nbLodgings > 1 ? 's' : '') +
    ( displaymode == 'logements' ? '</strong>' : '' ) +
    ' dans ' +
    ( displaymode == 'residences' ? '<strong>' : '' ) +
    nbResidencies + ' résidence' + (nbResidencies > 1 ? 's' : '') +
    ( displaymode == 'residences' ? '</strong>' : '' );

}

/**
 * Reset pagination markup
 */
function resetPagination() {

  if ( ! postsList ) return;

  const posts = postsList.querySelectorAll('.js-post-item');
  let maxNumPages = posts.length && posts[0].dataset.maxnumpages ? posts[0].dataset.maxnumpages : 1;
  let paged       = posts.length && posts[0].dataset.paged       ? posts[0].dataset.paged       : 1;

  // Ajax Request
  xhr = new XMLHttpRequest();
  xhr.open('GET', `${ajax_js.ajaxurl}?action=get_pagination_markup&maxnumpages=` + maxNumPages + `&paged=` + paged );
  xhr.onload = function() {
    if ( xhr.status === 200 && paginationElement ) {
      paginationElement.innerHTML += xhr.responseText;
    }
  };
  xhr.send();

}

/**
 * Init Map
 */
function initMap() {

  map = new google.maps.Map(mapElement, {
    zoom: defaultZoom,
    minZoom: 4,
    center: new google.maps.LatLng(defaultPositionLat, defaultPositionLng),
    draggable: true,
    scrollwheel: true,
    zoomControl: false,
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
    gestureHandling: "cooperative",
    mapTypeId: 'roadmap', // roadmap, satellite, terrain, hybrid
    // gestureHandling: 'greedy',
    styles: [
      {
        "featureType": "all",
        "elementType": "labels.text",
        "stylers": [
          {
            "color": "#878787"
          }
        ]
      },
      {
        "featureType": "all",
        "elementType": "labels.text.stroke",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
          {
            "color": "#f9f5ed"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
          {
            "color": "#f5f5f5"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#c9c9c9"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
          {
            "color": "#aee0f4"
          }
        ]
      }
    ]
  });

}

/**
 * Load Residencies on Map & Move Map according to results
 */
function loadResidenciesToMap(map){
  resetMap();

  loadPostsAjaxUrl = `/wp-json/urbat/residencies`;
  filtersSlug.forEach(filterSlug => {
    loadPostsAjaxUrl += '/' + filterSlug + '=' + ( currentFilters[filterSlug].length ? currentFilters[filterSlug].join('_') : 'all');
  });

  xhr = new XMLHttpRequest();
  xhr.open('GET', loadPostsAjaxUrl);
  xhr.onload = function() {
    addResidenciesIntoMap(JSON.parse(xhr.responseText), map);
  };
  xhr.send();
}

// Reset markers on the Map
function resetMap() {

  // Clear Map Markers & Infowindows
  markers = markers.map((marker, i) => {
    marker.setMap(null);
  });
  markers = [];
  if ( cluster )
    cluster.clearMarkers();

}

/**
 * Add Residencies on Map & Auto Center Map
 */
function addResidenciesIntoMap(residencies, map) {

  let lat_min = 999999;
  let lat_max = -999999;
  let lng_min = 999999;
  let lng_max = -999999;

  if ( residencies.length < 1 )
    return;

  // Create markers
  markers = residencies.map((residency, i) => {

    // Create marker
    const marker = new google.maps.Marker({
      id: residency.ID,
      map: map,
      position: { lat: residency.lat, lng: residency.lng },
      icon: {
        url: markerIcon,
        scaledSize: new google.maps.Size(30, 38),
      },
    });

    // Create infowindow
    Mustache.parse(residencyInfowindowTemplate);
    let infowindow = new google.maps.InfoWindow({
      content: Mustache.render(residencyInfowindowTemplate, {
        id                     : residency.ID,
        title                  : residency.post_title,
        lat                    : residency.lat,
        lng                    : residency.lng,
        permalink              : residency.permalink,
        address                : residency.address,
        city                   : residency.city,
        post_code              : residency.post_code,
        image                  : residency.image,
        lodging_types          : residency.lodging_types,
        tax_systems            : residency.tax_systems,
        progress               : residency.progress,
        delivery_years         : residency.delivery_years,
        offer                  : residency.offer,
        delivery_quarter       : residency.delivery_quarter,
        lodging_types_sentense : residency.lodging_types_sentense,
        price_sentence         : residency.price_sentence,
        is_multiplemaps        : residency.is_multiplemaps,
        color                  : residency.color,
      })
    });

    // Manage marker events
    marker.addListener("click", () => {
      // Click into map to close another potential openned infowindow, before opening the current one
      google.maps.event.trigger(map, 'click');
      infowindow.open(map, marker);
    });
    map.addListener("click", () => {
      infowindow.close();
    });

    // Update common vars
    if (residency.lat < lat_min) lat_min = residency.lat;
    if (residency.lat > lat_max) lat_max = residency.lat;
    if (residency.lng < lng_min) lng_min = residency.lng;
    if (residency.lng > lng_max) lng_max = residency.lng;

    return marker;
  });

  // Set Map fitBounds / center & zoom
  if( ! markers.length ){
    map.panTo(new google.maps.LatLng(defaultPositionLat, defaultPositionLng));
    smoothZoom(map, 5, map.getZoom());
  } else if(markers.length == 1){
    // Prevent too close zoom if only one marker
    let lat_center = (parseFloat(lat_max) + parseFloat(lat_min)) / 2.0;
    let lng_center = (parseFloat(lng_max) + parseFloat(lng_min)) / 2.0;
    map.panTo(new google.maps.LatLng(lat_center, lng_center));
    smoothZoom(map, 11, map.getZoom());
  }
  else{
    map.fitBounds(new google.maps.LatLngBounds(
      new google.maps.LatLng(lat_min, lng_min), // bottom left
      new google.maps.LatLng(lat_max, lng_max) // top right
    ));
    if ( map.getZoom() > maxZoom )
      map.setZoom(maxZoom);
  }

  // Add a marker clusterer to manage the markers.
  cluster = new MarkerClusterer({ markers, map });
}

/**
 * Smooth zoom
 */
function smoothZoom (map, level, cnt) {

  if(cnt == level)
    return;

  let direction = cnt < level ? cnt + 1 : cnt - 1;
  let z = google.maps.event.addListener(map, 'zoom_changed', function(event){
    google.maps.event.removeListener(z);
    smoothZoom(map, level, direction);
  });
  setTimeout(function(){map.setZoom(cnt)}, 80);

}

// Rewrite current page URL according to current filters
function rewriteURL() {

  if ( ! postsList ) return;
  // Prevent rewriting URL on thematic list pages
  if ( postsList.dataset.isthematiclist == 'true' )
    return;

  let newUrl = basename;
  let hasParameters = false;
  if ( displaymode != 'residences' ) {
    newUrl += '?m=' + displaymode;
    hasParameters = true;
  }
  for (let currentFilter in currentFilters) {
    if (currentFilters[currentFilter].length) {
      newUrl += (hasParameters ? '&' : '?') + currentFilter + '=' + currentFilters[currentFilter].join('_');
      hasParameters = true;
    }
  }

  window.history.pushState("", "", newUrl);
}

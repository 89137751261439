import TomSelect from 'tom-select';
import custom_reset from './tsCustomReset';
import checkbox_options from 'tom-select/dist/esm/plugins/checkbox_options/plugin';
import dropdown_input from 'tom-select/dist/esm/plugins/dropdown_input/plugin';
import { updateCurrentFiltersVar, reloadResidencies } from './residenciesListMap';

const tomSelectEl = document.querySelector('.js-location-selector');

TomSelect.define('custom_reset', custom_reset);
TomSelect.define('checkbox_options', checkbox_options);
TomSelect.define('dropdown_input', dropdown_input);

if (tomSelectEl) {
  const selectInstance = new TomSelect(tomSelectEl, {
    onChange: function(values) {
      updateSubmitButtonData(values, this);
    },
    onClear: function() {
      updateCurrentFiltersVar();
      reloadResidencies();
    },
    items: tomSelectEl.dataset.defaultlocations.split('_'),
    render: {
      item: function(data, escape) {
        const select = this;
        const item = document.createElement('span');
        item.setAttribute('data-nb-lodgings', data.nbLodgings)
        item.innerHTML = escape(data.text);

        const removeBtn = document.createElement('button');
        removeBtn.className = 'ts-remove-item';
        removeBtn.innerHTML = '✕';
        removeBtn.addEventListener('click', function(evt) {
          evt.stopPropagation();
          select.removeItem(data.value);
          updateCurrentFiltersVar();
          reloadResidencies();
        });

        item.appendChild(removeBtn);

        return item;
      },
      optgroup_header: function (data, escape) {
        const select = this;
        const optgroupHeader = document.createElement('button');
        optgroupHeader.className = 'optgroup-header';
        optgroupHeader.innerHTML = escape(data.label);
        optgroupHeader.addEventListener('click', function() {
          const checkboxes = Array.from(this.parentNode.getElementsByTagName('input'));
          const sameStatus = checkboxes.every((checkbox) => checkbox.checked === checkboxes[0].checked);

          for (const checkbox of checkboxes) {
            if (sameStatus && !checkbox.checked || !sameStatus) {
              checkbox.checked = true;
              select.addItem(checkbox.parentNode.dataset.value);
            } else {
              checkbox.checked = false;
              select.removeItem(checkbox.parentNode.dataset.value);
            }
          }
        });
        return optgroupHeader;
      },
      no_results:function(data,escape){
        return '<div class="no-results">Aucun résultat pour "'+escape(data.input)+'"</div>';
      },
    },
    plugins: {
      checkbox_options,
      dropdown_input,
      custom_reset: {
        resetClass: '',
        closeClass: 'js-update-data'
      }
    },
  });
}

function updateSubmitButtonData(values, selectInstance) {
  // Ajax Request
  const lodgingListLink = document.querySelector('.js-lodging-list-link span');
  if ( ! lodgingListLink )
    return values;

  let totalNbLodgings;

  if (values.length > 0) {
    totalNbLodgings = Number(0);
    values.forEach((key) => {
      totalNbLodgings += Number(selectInstance.options[key].nbLodgings);
    });
  } else {
    totalNbLodgings = lodgingListLink.parentNode.dataset.totalLodgings;
  }

  // Edit submit button text with number
  lodgingListLink.innerHTML = totalNbLodgings === '1' ? 'Voir le logement' : 'Voir les ' + totalNbLodgings + ' logements';

  let newUrl = `${lodgingListLink.parentNode.dataset.url}?m=residences`;
  if ( values && values.length ) {
    newUrl += '&lieu=' + values.join('_');
  }
  lodgingListLink.parentNode.setAttribute('href', newUrl);

}



